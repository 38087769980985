import { crmFilter } from '@/components/crm/filter.js'

export default function () {
  this.data = {}
  this.splitter = ';'
  this.keys = {}
  this.specialKeys = {}
  this.parent = {}
  this.filter = false

  this.setDefaultKeys = function () {
    this.note = this.parent.$tc('message.note', 1)
    this.checkIn = this.parent.$tc('message.checkIn', 1)
    this.checkOut = this.parent.$tc('message.checkOut', 1)
    this.group = this.parent.$t('message.appointmentFormat')
    this.keys.ID = {}
    this.keys[this.group] = {}
    this.keys.status = {}
    this.keys.begin = {}
    this.keys.end = {}
    this.keys[this.checkIn] = {}
    this.keys[this.checkOut] = {}
    this.keys.service = {}
    this.keys.tel = {}
    this.keys.mail = {}
    this.keys[this.note] = {}
  }

  this.setSpecificKeys = function () {
    Object.values(this.data).forEach(
      function (entries) {
        Object.keys(entries.data).forEach(
          function (key) {
            this.keys[key] = {}
            this.specialKeys[key] = {}
          }.bind(this)
        )
      }.bind(this)
    )
  }

  this.getFilterData = function () {
    if (!this.filter) {
      return this.data
    }
    var filtered = []
    Object.values(this.data).forEach(
      function (entry) {
        if (this.checkFilter(entry)) {
          filtered.push(entry)
        }
      }.bind(this)
    )
    return filtered
  }

  this.checkFilter = function (entry) {
    var f = new crmFilter(this.filter)
    if (!f.check(entry)) {
      return false
    }

    return true
  }

  this.setGenerateRows = function () {
    Object.values(this.data).forEach(
      function (entry) {
        var ID = entry.booking_id

        if (typeof entry.data[this.note] === 'undefined') {
          this.keys[this.note][ID] = '---'
        }
        this.keys.ID[ID] = ID
        this.keys.mail[ID] = entry.email.replace(/(<([^>]+)>)/gi, '').trim()
        if (!this.keys.mail[ID]) {
          this.keys.mail[ID] = '---'
        }
        this.keys.tel[ID] = entry.tel
        if (!this.keys.tel[ID]) {
          this.keys.tel[ID] = '---'
        }
        this.keys.status[ID] = this.parent.$t('message.' + entry.status)

        this.keys.begin[ID] = entry.begin
        this.keys.end[ID] = entry.end
        this.keys[this.group][ID] = this.parent.$getGroupTxt(entry, this.parent)
        this.keys[this.checkIn][ID] = entry.check_in
        this.keys[this.checkOut][ID] = entry.check_out
        if (!this.keys[this.checkIn][ID]) {
          this.keys[this.checkIn][ID] = '---'
        }
        if (!this.keys[this.checkOut][ID]) {
          this.keys[this.checkOut][ID] = '---'
        }
        if (
          entry.service !== null &&
          typeof entry.service === 'object' &&
          typeof entry.service.title !== 'undefined'
        ) {
          this.keys.service[ID] =
            'Titel: ' +
            entry.service.title +
            ' | Beschreibung: ' +
            entry.service.description +
            ' | Zeit in Min: ' +
            entry.service.time +
            ' | Preis in EUR: ' +
            entry.service.price
        } else {
          this.keys.service[ID] = '---'
        }

        this.setGenerateSpecificRow(ID, entry)
      }.bind(this)
    )
  }

  this.setGenerateSpecificRow = function (ID, entry) {
    Object.keys(this.specialKeys).forEach(
      function (key) {
        if (typeof entry.data[key] === 'undefined') {
          this.keys[key][ID] = '---'
        } else if (typeof entry.data[key] === 'object' && typeof entry.data[key].src !== 'undefined') {
          this.keys[key][ID] = entry.data[key].src
        } else if (typeof entry.data[key] === 'object') {
          this.keys[key][ID] = entry.data[key].join(' | ')
        } else if (!entry.data[key] || entry.data[key] === '') {
          this.keys[key][ID] = '---'
        } else {
          this.keys[key][ID] = entry.data[key]
        }

        this.keys[key][ID].split(';').join(',')
      }.bind(this)
    )
  }

  this.generateCSV = function () {
    this.csv =
      '"' + Object.keys(this.keys).join('"' + this.splitter + '"') + '"'

    Object.keys(this.data).forEach(
      function (ID) {
        this.csv += '\n'
        var row = []
        Object.keys(this.keys).forEach(
          function (key) {
            row.push(Object.values(this.keys[key])[ID])
          }.bind(this)
        )
        this.csv += '"' + row.join('"' + this.splitter + '"') + '"'
      }.bind(this)
    )
  }

  this.get = function () {
    this.data = this.getFilterData()
    this.setDefaultKeys()
    this.setSpecificKeys()
    this.setGenerateRows()
    this.generateCSV()
  }
}
