<template>
  <div >
    <a href="#" @click.prevent="setStartDownload()" :class="className"><flixIcon :id="'download'" /> <slot></slot></a>
    <entryPDF :key="updateKey" v-if="downloadPDF" :data="getFilterEntry()" :onSuccess="function () { downloadPDF = false }"/>
    <div class="flix-text-danger flix-text-center" v-if="error">{{ $tc('message.noEntry', 2) }}</div>
  </div>
</template>
<script>
import csv from '@/components/csv/csv.js'
export default {
  components: {
    entryPDF () { return import('@/components/pdf/entry') }
  },
  props: {
    entry: Array,
    className: Object,
    filter: {
      type: [String, Date, Boolean],
      default () { return 'all' }
    }
  },
  data () {
    return {
      downloadPDF: false,
      updateKey: new Date().getTime(),
      error: false
    }
  },
  methods: {
    setStartDownload () {
      this.error = false
      if (!this.getFilterEntry().length) {
        this.error = true
        setTimeout(function () {
          this.error = false
        }.bind(this), 2000)
        return false
      }
      this.updateKey = new Date().getTime()
      this.downloadPDF = true
    },
    getFilterEntry () {
      var c = new csv()
      c.data = this.entry
      c.filter = this.filter
      return c.getFilterData()
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
