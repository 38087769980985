import Vue from 'vue'

var crmFilter = function (method) {
  this.check = function (d) {
    this.data = d
    var r = false
    switch (method) {
      case 'today':
        r = this.checkToday()
        break
      case '+1week':
        r = this.checkPlus1Week()
        break
      case '-1week':
        r = this.checkMinus1Week()
        break
      case 'tomorrow':
        r = this.checkPlus1Day()
        break
      case 'yesterday':
        r = this.checkMinus1Day()
        break
      case '+2day':
        r = this.checkPlus2Day()
        break
      case '+3day':
        r = this.checkPlus3Day()
        break
      case '-2day':
        r = this.checkMinus2Day()
        break
      case '-3day':
        r = this.checkMinus3Day()
        break
      case '+1month':
        r = this.checkPlus1Month()
        break
      case '-1month':
        r = this.checkMinus1Month()
        break
      case 'all':
        r = true
        break
      default:
        r = this.checkSpecificDate(method)
        break
    }
    return r
  }
  this.checkSpecificDate = function (date) {
    var today = {
      begin: new Date(date.getTime()),
      end: new Date(date.getTime())
    }
    today.begin.setHours(0)
    today.begin.setMinutes(0)
    today.end.setHours(23)
    today.end.setMinutes(59)

    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkToday = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkPlus1Day = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    // today.begin.setDate(today.begin.getDate() + 1)
    today.end.setDate(today.end.getDate() + 1)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkMinus1Day = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    // today.begin.setDate(today.begin.getDate() - 1)
    today.end.setDate(today.end.getDate() - 1)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkMinus2Day = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    // today.begin.setDate(today.begin.getDate() - 2)
    today.end.setDate(today.end.getDate() - 2)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkMinus3Day = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    // today.begin.setDate(today.begin.getDate() - 3)
    today.end.setDate(today.end.getDate() - 3)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkPlus2Day = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    // today.begin.setDate(today.begin.getDate() + 2)
    today.end.setDate(today.end.getDate() + 2)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkPlus3Day = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    // today.begin.setDate(today.begin.getDate() + 3)
    today.end.setDate(today.end.getDate() + 3)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkPlus1Week = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    today.end.setDate(today.end.getDate() + 7)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkPlus1Month = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    // today.begin.setDate(today.begin.getDate() + 1)
    today.end.setMonth(today.end.getMonth() + 1)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkMinus1Month = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    today.end.setDate(today.end.getDate() - 1)
    today.begin.setMonth(today.begin.getMonth() - 1)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
  this.checkMinus1Week = function () {
    var today = {
      begin: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)
    }
    today.end.setDate(today.end.getDate() - 1)
    today.begin.setDate(today.begin.getDate() - 7)
    if (Vue.prototype.$createDate(this.data.end).getTime() >= today.begin.getTime() && Vue.prototype.$createDate(this.data.begin).getTime() <= today.end.getTime()) {
      return true
    } else {
      return false
    }
  }
}

export { crmFilter }
